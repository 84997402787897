@import "/vercel/path0/frontend/scss/common/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/functions";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/mixins";
.box {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: currentColor;
  transition: all 0.3s ease;

  @media (min-width: breakpoint-min(md)) {
    padding: 1rem;
  }

  a:hover {
    text-decoration: none;
  }

  .inner {
    flex-grow: 1;
    padding: 1.5rem 0;
  }

  .name {
    font-size: 0.85rem;
    font-weight: 700;
  }

  .meta {
    padding-bottom: 0.875rem;
    margin-bottom: 1.5rem;
    overflow: hidden;
    font-size: 0.75rem;
    border-bottom: 1px solid #e4e4e4;

    @media (min-width: breakpoint-min(md)) {
      margin-bottom: 0;
    }
  }

  .code {
    color: #5c6670;
  }

  .cart {
    position: relative;
    z-index: 2;
    padding: 0;
    margin: 0;
    color: theme-color();
    user-select: none;
    background: transparent;
    border: 0;
    transition: all 0.3s ease;
    transform: translateX(0);

    @media (min-width: breakpoint-min(md)) {
      transform: translateX(calc(100% - 1.45rem));
    }
  }

  .cartIcon {
    width: 1.45rem;
    height: 1.45rem;
    margin-right: 0.625rem;
  }

  .cartText {
    font-weight: 700;
  }

  &:hover {
    @media (min-width: breakpoint-min(md)) {
      background-color: #fff;
      box-shadow: 0 16px 40px rgb(0 0 0 / 10%);
    }

    .cart {
      transform: translateX(0);
    }
  }
}
