@import "/vercel/path0/frontend/scss/common/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/functions";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/mixins";
.container {
  position: relative;
  max-width: 380px;
  padding: 0 15px;
  margin: 0 auto;

  @media (width >= 768px) {
    width: 160vw;
  }

  @media (width >= 1024px) {
    left: -10vw;
    width: 120vw;
    max-width: 500%;
  }

  @media (width >= 1580px) {
    left: 0;
    max-width: 1600px;
  }

  svg {
    width: 100%;
    height: auto;
  }

  [role="button"] {
    cursor: default;
  }
}
