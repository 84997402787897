@import "/vercel/path0/frontend/scss/common/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/functions";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/mixins";
.slide {
  height: 100%;
  padding: 5.25rem 0;
  text-align: center;
  background: 50% 50% / cover;

  @media (min-width: breakpoint-min(md)) {
    padding: 6.25rem 0;
  }
}

.title {
  position: relative;
  padding-bottom: 1.6rem;
  margin-bottom: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.047rem;

  @include font-size(2.25rem);

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 2.5rem;
    height: 0.188rem;
    content: "";
    background-color: theme-color();
    transform: translateX(-50%);
  }
}

.content {
  max-width: 40rem;
  padding: 0 2rem;
  margin: 0 auto;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.38;
  letter-spacing: 0.013rem;
}

.arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  color: theme-color();
  background: transparent;
  border: 0;
  transform: translateY(-50%);

  &:focus {
    outline: none;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }

  &:global(.slick-disabled) {
    pointer-events: none;
    opacity: 0%;
  }

  &.left {
    left: 0.81rem;
  }

  &.right {
    right: 0.81rem;
  }
}

.slider {
  :global .slick-slide > div {
    width: 100%;
  }

  :global .slick-slide > div > div {
    height: 100%;
  }
}
