@import "/vercel/path0/frontend/scss/common/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/functions";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/mixins";
.box {
  display: block;
  text-align: center;

  &::after,
  .link,
  .image {
    transition: all 0.5s ease;
  }

  &::after {
    position: absolute;
    top: -1.5rem;
    left: -1rem;
    display: block;
    width: calc(100% + 2rem);
    height: calc(100% + 3rem);
    content: "";
    background: #fff;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.04);
    opacity: 0;
  }

  .image {
    position: relative;
    z-index: 1;
    padding-bottom: 40%;
    color: #fff;

    @media (min-width: breakpoint-min(md)) {
      padding-bottom: 74%;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      content: "";
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 100%
      );
    }

    .inner {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
    }

    .icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 4.25rem;
      height: 4.25rem;
      background: theme-color();
      border-radius: 50%;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .title {
      margin-top: 0.75rem;
      font-weight: 700;
      text-align: center;
      letter-spacing: 0.3px;
    }
  }

  .link {
    position: relative;
    z-index: 1;
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 1.25rem;
    font-size: 0.875rem;
    font-weight: 700;
    color: theme-color();
    text-decoration: underline;
    letter-spacing: 0.3px;
    opacity: 0;
    transition: all 0.5s ease;

    @media (min-width: breakpoint-min(md)) {
      display: inline-flex;
    }

    svg {
      width: 0.875rem;
      height: 0.875rem;
      margin-left: 0.5rem;
    }
  }

  @media (min-width: breakpoint-min(md)) {
    &:hover {
      &::after,
      .link {
        opacity: 1;
      }

      .image {
        transform: scale(1.05);
      }
    }
  }
}
